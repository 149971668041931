<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondInputTab.vue
Description: This file contains UI components used to display pond inputs records showing in inputs tab of ponds page in overview section
-->
<template>
  <el-row class="pond-input">
    <er-card v-loading="loading" element-loading-background="white">
      <div class="loading_class" v-if="loading"></div>
      <er-data-tables
        ref="pondInputTab"
        v-show="!loading"
        :columns="tableColumns"
        :tableData="tableData"
        :el-table-props="tableProps"
        :actionColumn="false"
        type="white-header-table"
        uniqueId="mineral-medicine-table"
        :key="$i18n.locale"
      >
        <!-- <template v-slot:resource_name="{ row }">{{
          row.data.type === 'OTHER' ?  row.data.resource_name : row.data.resource.name
        }}</template> -->
        <template v-slot:resource_type="{ row }">{{
          resourceTypes[row.data.type]
        }}</template>
        <template v-slot:quantity="{ row }">
          {{ ftm__digitPrecision(row.data.quantity, 2) }}
          <!-- {{
            row.data.resource
              ? getUnits(row.data.resource.units)
              : getUnits(row.data.units)
          }} -->
        </template>
        <template v-slot:event_date="{ row }">{{
          getEventDate(row.data.date)
        }}</template>
        <template v-slot:notes="{ row }">
          <div class="notes-container">
            <el-tooltip
              :content="row.data.notes"
              placement="bottom"
              effect="light"
            >
              <!-- <p>{{ row.data.notes.substring(0, 24) }}</p> -->
              <p>{{ row.data.notes }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:actions="{ row }">
          <er-button
            type="text"
            size="mini"
            btnType="edit"
            :showLabel="true"
            :showIcon="true"
            @click="handleEditRecord(row.data)"
            :disabled="disabledBtn"
          ></er-button>
          <el-divider direction="vertical" style="height: 100%"></el-divider>
          <er-button
            type="text"
            size="mini"
            btnType="delete"
            :showLabel="true"
            :showIcon="true"
            @click="handleDeleteRecord(row.data)"
            :disabled="disabledBtn"
          ></er-button>
        </template>
      </er-data-tables>
    </er-card>
    <er-dialog
      :visible.sync="dialogVisible"
      width="25%"
      :close-on-click-modal="false"
      class="pond-input-tab"
      custom-class="pondInput_dialog"
      :title="`${$t('Comn_update')} ${$t('Comn_inputs')}`"
    >
      <el-form size="small" :model="editMedRec" :hide-required-asterisk="true">
        <el-form-item :label="$t('Medicines_variety')" prop="type">
          <el-col :span="22">
            <er-select
              v-model="editMedRec.type"
              size="small"
              @change="handleChangeResourceType($event, 'type', editMedRec)"
              :placeholder="$t('Medicines_variety')"
              disabled
            >
              <el-option
                v-for="(resourceLabel, resourceKey) in resourceTypes"
                :key="resourceKey"
                :value="resourceKey"
                :label="$t(resourceLabel)"
              ></el-option>
            </er-select>
          </el-col>
        </el-form-item>
        <div v-if="editMedRec.type != 'OTHER'">
          <el-form-item :label="$t('Comn_resource')" prop="resource">
            <el-col :span="22">
              <er-select
                v-model="editMedRec.resource._id"
                size="small"
                @change="
                  handleChangeResourceType($event, 'resource', editMedRec)
                "
                :placeholder="$t('Comn_resource')"
              >
                <el-option
                  v-for="resource in getArrResources"
                  :key="resource._id"
                  :value="resource._id"
                  :label="resource.name"
                ></el-option>
              </er-select>
            </el-col>
          </el-form-item>
        </div>
        <div v-else class="others-container">
          <el-form-item
            :label="$t('Comn_resource')"
            :error="
              ekmm__castErrorKeyToLang(ehm__errMessagesObject.resource_name)
            "
          >
            <el-col :span="22">
              <el-input
                :placeholder="$t('Comn_others')"
                v-model="editMedRec.resource_name"
                size="small"
              ></el-input>
            </el-col>
          </el-form-item>
        </div>

        <el-form-item
          :label="$t('Comn_quantity')"
          prop="quantity"
          :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.quantity)"
        >
          <el-col :span="22">
            <!-- <el-input-number
              v-model="editMedRec.quantity"
              size="small"
              :controls="false"
              :min="0"
              :max="100000"
            ></el-input-number> -->
            <el-input
              type="number"
              v-model="editMedRec.quantity"
              :controls="false"
              size="small"
              :min="0"
              :max="100000"
              :precision="2"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item
          :label="$t('Comn_units')"
          v-if="editMedRec.type === 'OTHER'"
        >
          <el-col :span="22">
            <el-select
              v-model="editMedRec.units"
              size="small"
              :placeholder="$t('Comn_units')"
            >
              <el-option
                v-for="unit in units"
                :key="unit.key"
                :value="unit.key"
                :label="unit.name"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('Comn_units')" v-else>
          <el-col :span="22">
            <el-input
              :value="
                this.editMedRec.units
                  ? unitsObj[this.editMedRec.units]
                  : unitsObj[this.editMedRec.resource.units]
              "
              disabled
              size="small"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('Comn_notes')" prop="notes">
          <el-col :span="22" class="text-input-container">
            <el-input
              size="small"
              type="textarea"
              autosize
              :rows="4"
              :placeholder="$t('Comn_note_here')"
              v-model="editMedRec.notes"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <layout-toolbar slot="footer" justify="center">
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          @click="decideActionAndPrepPayload()"
          :disabled="isReadOnly"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="closeDialogBox"
          :disabled="isReadOnly"
        ></er-button>
      </layout-toolbar>
    </er-dialog>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";

export default {
  name: "pondInputTab",
  props: ["tabData"],

  mixins: [
    errorHandlerMixin,
    filtersMixin,
    notificationMixin,
    errorKeyMapMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      newErrObject: {
        quantity: {},
        resource_name: {}
      },
      ehm__errMessagesObject: {
        quantity: {},
        resource_name: {}
      },
      tableData: [],
      loading: false,
      tableProps: {
        defaultSort: {
          prop: "resource_name",
          order: "ascending"
        },
        height: "calc(100vh - var(--height-gutter))"
      },
      dialogVisible: false,
      editMedRec: {
        pond_id: "",
        type: "",
        resource: "",
        quantity: "",
        date: "",
        _id: ""
      },
      editMedRecBrforeChange: {},
      units: [
        { name: "Kg", key: "KG" },
        { name: "Lt", key: "L" },
        { name: "Units", key: "UNITS" }
      ],
      unitsObj: {
        KG: "Kg",
        L: "Lt",
        UNITS: "Units"
      },
      ekmm__backndfieldToFieldNameMap: {
        resource_name: "Comn_resource_name",
        quantity: "Comn_quantity"
      },
      ekmm__backndfieldToInvalidMap: {
        quantity: {
          VALUE_SHOULD_BE_A_SAFE_NUMBER: 0
        },
        resource_name: {
          INVALID_VALUE: "Resource_can_not_be_empty"
        }
      }
    };
  },
  computed: {
    ...mapGetters("pondDetails", {
      getSelectedPondName: "getSelectedPondName",
      getSelectedPondMedcnsMinrls: "getSelectedPondMedcnsMinrls",
      getCurrentCulture: "getCurrentCulture"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getUserCountryCode: "getUserCountryCode",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    ...mapGetters("resource", {
      getArrResources: "getArrResources"
    }),
    disabledBtn() {
      return this.isReadOnly || !this.getCurrentCulture.is_running;
    },
    resourceTypes() {
      return {
        MEDICINE: this.$t("Comn_stock_medicine"),
        MINERAL: this.$t("Comn_mineral"),
        PRO_BIOTIC: this.$t("Medicine_pro_biotic"),
        DO_SUBSTITUTE: this.$t("Comn_stock_do_substitute"),
        WATER_CONDITIONER: this.$t("Comn_stock_water_conditioner"),
        CARBON_SOURCE: this.$t("Comn_stock_carbon_source"),
        DISINFECTANT: this.$t("Comn_stock_disinfectant"),
        FERTILIZER: this.$t("Comn_stock_fertilizer"),
        FEED_ADDITIVES: this.$t("Comn_stock_feed_additives"),
        ORGANIC_ACID: this.$t("Comn_stock_organic_acid"),
        OTHER: this.$t("Comn_others")
      };
    },
    tableColumns() {
      return {
        resource_name: {
          prop: "resource.name",
          label: this.$t("Resource_name"),
          sortable: true,
          minWidth: 100
        },
        resource_type: {
          prop: "type",
          label: this.$t("Resource_resource_type"),
          sortable: true,
          minWidth: 100
        },
        quantity: {
          prop: "quantity",
          label: this.$t("Reports_quantity_applied"),
          sortable: true,
          minWidth: 100
        },
        event_date: {
          prop: "date",
          label: this.$t("Medicines_event_date"),
          sortable: true,
          minWidth: 100
        },
        notes: {
          prop: "notes",
          label: this.$t("Comn_notes"),
          sortable: true,
          minWidth: 100
        },
        actions: {
          prop: "actions",
          label: this.$t("Comn_actions"),
          minWidth: 80
        }
      };
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("pondDetails", {
      fetchResourcesByPondId: "fetchResourcesByPondId"
    }),
    ...mapActions("medicines", {
      addMedicinesToPonds: "addMedicinesToPonds",
      deleteMedicinesAndMineralsInPonds: "deleteMedicinesAndMineralsInPonds"
    }),
    ...mapActions("resource", {
      fetchAllResources: "fetchAllResources"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    getUnits(unit) {
      return this.units.filter(({ key }) => key === unit)[0].name;
    },
    getEventDate(date) {
      return this.dhm__formatByTZOnUTCWithLocale(
        date,
        this.upm__getFormatDateString
      );
    },
    async initComponent() {
      this.loading = true;
      try {
        await this.fetchResourcesByPondId();
        await this.fetchAllResources({
          type: "MEDICINE",
          // include_deleted: true,
          get_all: true
        });
        this.tableData = this.$lodash.cloneDeep(this.getSelectedPondMedcnsMinrls)
        .map(res => {
          if (res?.type === "OTHER") {
            const resource = {
              name: res.resource_name,
              type: res.type,
              units: res.units
            }
            res = { ...res, resource: resource }
          }
          return res;
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.pondInputTab) {
            this.$refs.pondInputTab.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    async handleDeleteRecord(record) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.nofm__deleteConfirmation(
          this.$t("Comn_resource"),
          this.$t("Comn_inputs")
        );
        this.loading = true;
        await this.deleteMedicinesAndMineralsInPonds(record._id);
        this.mixPanelEventGenerator({ eventName: "Ponds - Inputs - Table Data - Delete" });
        await this.initComponent();
        this.nofm__notifySuccess({
          msgLangkey: {
            title: "Comn_delete_success_msg",
            count: 0,
            child: {
              item: record.resource_name
                ? record.resource_name
                : record.resource.name,
              source: this.getSelectedPondName
            }
          }
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleEditRecord(editMedRec) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newErrObject);
        this.editMedRec = JSON.parse(JSON.stringify(editMedRec));
        this.editMedRecBrforeChange = JSON.parse(JSON.stringify(editMedRec));
        await this.fetchAllResources({
          type: editMedRec.type,
          // include_deleted: true
        });
        this.mixPanelEventGenerator({ eventName: "Ponds - Inputs - Table Data - Edit" });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.dialogVisible = true;
      }
    },
    async decideActionAndPrepPayload() {
      if (this.editMedRec.type !== 'OTHER' && this.editMedRecBrforeChange.quantity === this.editMedRec.quantity && this.editMedRecBrforeChange.resource?._id === this.editMedRec.resource?._id &&
          this.editMedRecBrforeChange?.notes === this.editMedRec?.notes) {
          this.ehm__errorMessages({
            type: "FAIL_TO_SAVE",
            errors: [{ message: this.$t("Comn_no_changes_found") }]
          }, true);
          return;
          }
      if (this.editMedRec.type === 'OTHER' && this.editMedRecBrforeChange.quantity === this.editMedRec.quantity && this.editMedRec.resource_name !== '' && this.editMedRecBrforeChange.resource_name === this.editMedRec.resource_name && this.editMedRecBrforeChange.units === this.editMedRec.units &&
        this.editMedRecBrforeChange?.notes === this.editMedRec?.notes) {
        this.ehm__errorMessages({
          type: "FAIL_TO_SAVE",
          errors: [{ message: this.$t("Comn_no_changes_found") }]
        }, true);
        return;
      }
      this.loading = true;
      try {
        const {
          pond_id,
          type,
          resource,
          resource_name,
          quantity,
          date,
          _id,
          notes,
          units
        } = this.editMedRec;
        const updatePayload = [];
        const name = resource_name || (resource ? resource.name : "");

        if (!this.editMedRec.resource || (this.editMedRec.type === 'OTHER' && !this.editMedRec.resource_name)) {
          this.ehm__errMessagesObject.resource_name = {
            error_code: "INVALID_VALUE",
            key: "resource_name"
          };
          // return;
        } else if (!quantity || quantity === "0") {
          this.ehm__errMessagesObject.quantity = {
            error_code: "VALUE_SHOULD_BE_A_SAFE_NUMBER",
            key: "quantity"
          };
        } else {
          if (resource && type !== "OTHER") {
          updatePayload.push({
            pond_id,
            type,
            resource: resource._id,
            quantity: Number(quantity)?.toFixed(2),
            date,
            _id,
            ...(notes && {
              notes
            })
          });
        } else {
          updatePayload.push({
            pond_id,
            type,
            resource_name: resource_name.trim(),
            units,
            quantity: Number(quantity)?.toFixed(2),
            date,
            _id,
            ...(notes && {
              notes
            })
          });
        }
          await this.addMedicinesToPonds(updatePayload);
          await this.initComponent();
          this.dialogVisible = false;
          this.nofm__notifySuccess({
            msgLangkey: {
              title: "Comn_update_success_msg",
              count: 0,
              child: {
                item: name || resource_name,
                source: this.getSelectedPondName
              }
            }
          });
        }
      } catch (error) {
        let formatedError = {};
        if (
          error.response &&
          error.response.data.errors.details[0].error_code ===
            "RESOURCE_ALREADY_EXISTS"
        ) {
          formatedError = {
            type: "FAIL_TO_SAVE",
            errors: [{ message: this.$t("resource_already_exists") }]
          };
          this.ehm__errorMessages(formatedError, true);
        } else {
          formatedError = error;
        }
        this.ehm__errorMessages(formatedError, true);
      } finally {
        this.loading = false;
      }
    },
    async handleChangeResourceType(resourceType, type, data) {
      if (type === "type") {
        await this.fetchAllResources({
          type: resourceType,
          // include_deleted: true
        });
        data.resource = "";
      } else {
        data.units = this.getArrResources.filter(
          res => res._id === data.resource._id
        )[0].units;
        // data.type = "";
      }
    },
    closeDialogBox() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.pond-input {
  @include responsiveProperty(--height-gutter, 235px, 248px, 260px);
  .notes-container {
    @include responsiveProperty(width, 140px, 180px, 200px);
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .pondInput_dialog {
    background: #fcfcfc;
    border-radius: 10px;
    .el-input--small .el-input__inner {
      @include responsiveProperty(width, 160px, 189px, 229px);
    }

    .el-form-item--small .el-form-item__content,
    .el-form-item--small .el-form-item__label {
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      @include responsiveProperty(min-width, 105px, 100px, 140px);
    }
    .text-input-container {
      .el-input--small {
        @include responsiveProperty(width, 160px, 189px, 229px, true);
      }
    }
  }
  .loading_class {
    @include responsiveProperty(height, 67.5vh, 72vh, 77vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
}
</style>
