<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgStatusGraph.vue
Description: This file is the chart component that displays water quality parameters sent by the pondguard (such as DO & Temp) in the ponds page. There 3 yaxis which represents values of DO, Temperature, pH respectively, where as x axis the time.
-->
<template>
  <er-card
    v-loading="loading"
    element-loading-background="white"
    class="pg-status-graph"
    ref="pond-guard"
  >
    <layout-toolbar class="pg-graph-filter" slot="header" :gap="2">
      <p class="card-title">{{ $t("Comn_pond_guard") }}</p>
      <div class="filler"></div>
      <er-select
        :key="pondId"
        class="select-pond-guard"
        v-model="selectPG"
        value-key="device_id"
        @change="handleChangeInPg"
        size="mini"
        :placeholder="$t('Ponds_select_pond_quards')"
      >
        <el-option
          v-for="pg in listPondGuards"
          :key="pg.device_code"
          :value="pg"
          :label="pg.device_code"
        ></el-option>
      </er-select>

      <er-select
        class="select-graph-data-type"
        :value="dataViewOption"
        @change="handleChangeCommand"
        size="mini"
      >
        <el-option :label="$t('Pond_raw_data')" value="RAW_DATA"></el-option>
        <el-option :label="$t('Comn_avrg_data')" value="AVG_DATA"></el-option>
      </er-select>

      <er-date-picker
        type="datetimerange"
        unlink-panels
        size="mini"
        :value-format="dateFormat"
        :timeZoneString="getUserTimeZoneString"
        popper-class="pg-date-picker"
        v-model="dateRange"
        :format="formatDateAndTime"
        :default-time="['12:00:00']"
        :start-placeholder="$t('Comm_Start')"
        :end-placeholder="$t('Comn_End')"
        :range-separator="$t('Comn_to')"
        :disableDateMethod="chm__disableDateMethod"
        :availableInterval="chm__availableInterval"
        :arrShortcuts="arrDatePickerShortCuts"
        :clearable="false"
        @change="handleDateValueChange"
      ></er-date-picker>

      <er-dropdown
        placement="bottom-end"
        @item-click="handleDownloadButton"
        class="er-dropdown"
        :listOfItems="['Comn_download']"
      >
        <template class="el-dropdown-link download_button" slot="suffix">
          &#8942;
        </template>
        <template v-slot:item-title="{ item }">{{ $t(item.value) }}</template>
      </er-dropdown>
    </layout-toolbar>

    <el-row :key="$i18n.locale">
      <high-charts
        ref="highcharts"
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import chartMixin from "@/mixins/chartMixin";
import { pgStatusGraphConfig } from "./chartOptions";
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import XLSX from "xlsx";
import moment from "moment";
export default {
  mixins: [
    errorHandlerMixin,
    chartMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    notificationMixin
  ],
  data: function() {
    return {
      sensor_hourly_logs: [],
      dataViewOption: "RAW_DATA",
      dateRange: [],
      listPondGuards: [],
      selectPG: undefined,
      loading: false,
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null
      },
      chartOptions: pgStatusGraphConfig,
      chm__yAxisTextKey: [],
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      },
      timeFormatObject: {
        "HH:mm": "%H:%M",
        "hh:mm a": "%l:%M %p"
      },
      sensor_Permissions: {},
    };
  },
  computed: {
    ...mapGetters("pondDetails", {
      getArrSelectedPondPGs: "getArrSelectedPondPGs"
    }),
    ...mapGetters("user", {
      getUserId: "getUserId",
      getCreatedBy: "getCreatedBy",
      getUserTimeZoneString: "getUserTimeZoneString",
      getPreferredUnits: "getPreferredUnits"
    }),
    formatDateAndTime() {
      return this.upm__getFormatDateAndTimeString;
    },
    criticalLowerLimit() {
      if (!this.selectPG) return 1.5;
      if (!this.selectPG.do_alerts_config) return 1.5;
      if (this.selectPG.do_alerts_config.length === 0) return 1.5;
      return this.selectPG.do_alerts_config[0].critical_lower_limit || 1.5;
    },
    lowerLimit() {
      if (!this.selectPG) return 2.5;
      if (!this.selectPG.do_alerts_config) return 2.5;
      if (this.selectPG.do_alerts_config.length === 0) return 2.5;
      return this.selectPG.do_alerts_config[0].lower_limit || 2.5;
    },
    setPondGuards() {
      return new Set(this.listPondGuards.map((currPg) => currPg._id));
    },
    dateFormat() {
      return "yyyy-MM-dd HH:mm";
    },
    arrDatePickerShortCuts: function() {
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "minutes"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(1, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(3, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(6, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(12, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(24, "hours"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(7, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(15, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(3, "months"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(6, "months")
      ];
    },
    chm__defaultDateObjDtRangeForCurrCulture() {
      return this.dhm__dateUtilsLib.getDateRangeFromRefDate({
        referenceDate: this.dhm__getTodayInUserTZ,
        distanceFromRefDate: [{ action: "subtract", params: [{ hours: 6 }] }],
        timeZone: this.getUserTimeZoneString,
        actionsOnReferenceDate: [],
        actionsOnDateRangeItem: []
      });
    }
  },
  mounted() {
    this.chm__registerResizeObserver("pond-guard");
  },
  beforeDestroy() {
    this.chm__unRegisterResizeObserver("pond-guard");
  },
  methods: {
    ...mapActions("pondDetails", {
      fetchPondGuardData: "fetchPondGuardData",
      fetchPondPGsInCurrCulture: "fetchPondPGsInCurrCulture",
      fetchAquaLabPondGuardData: "fetchAquaLabPondGuardData"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    formatDate: function(date) {
      try {
        return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          date,
          this.upm__getFormatDateAndTimeString,
          // this.getUserTimeZoneString,
          this.dhm__getLocaleObj
        );
      } catch (err) {
        return "NA";
      }
    },
    async handleDownloadButton() {
      if (!this.selectPG) return;
      if (!this.sensor_hourly_logs.length) {
        try {
          await this.nofm__noDataToDownload(this.selectPG.device_code);
        } catch (err) {
          console.log(err);
        }
        return;
      }
      const payload = [];
      for (const {
        pond_guard_code,
        data: { temperature, date, dissolved_oxygen }
      } of this.sensor_hourly_logs) {
        payload.push({
          [this.$t("Comn_device_code")]: pond_guard_code,
          [this.$t("PM_dissolved_oxygen")]: dissolved_oxygen,
          [this.$t("Comn_temperature")]: temperature,
          [this.$t("Comn_date")]: this.formatDate(date)
        });
      }
      const xlsxFilterByDate = payload.sort((a, b) =>
        this.dhm__dateUtilsLib.dateComparator(
          a[this.$t("Comn_date")],
          b[this.$t("Comn_date")],
          true
        )
      );
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlsxFilterByDate);
      XLSX.utils.book_append_sheet(wb, ws);
      const exportFileName = `${this.selectPG.device_code}.csv`;
      XLSX.writeFile(wb, exportFileName, {
        type: "binary",
        cellDates: true
      });
      this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - PondGuard Graph - Download" });
    },

    getFormat(date) {
      return this.dhm__dateUtilsLib.formatDate(date, this.dateFormat);
    },
    getShortCutObj(no, unit, customText = undefined) {
      return {
        no,
        unit,
        text: customText || `${this.$t("Comn_last")} ${no} ${this.$tc(unit, 2)}`
      };
    },
    chm__initDateRangeWithMomentObj(dateObjArr) {
      const dates = dateObjArr.map((x) => {
        return this.dhm__formatOnDateObjWithLocale(x, this.dateFormat);
      });
      this.$set(this.dateRange, 0, dates[0]);
      this.$set(this.dateRange, 1, dates[1]);
      this.handleValueChange(this.dateRange);
    },
    async initComponent() {
      this.loading = true;
      try {
        await this.fetchPondPGsInCurrCulture({
          pondId: this.pondId,
          params: {
            from_date: this.params.from_date,
            to_date: this.params.to_date
          }
        });
        await this.manageApiCallByUserId();
        this.handleCommand(this.dataViewOption);
      } catch (err) {
        this.ehm__errorMessages(err, true);
        this.listPondGuards = [];
        this.selectPG = undefined;
        this.sensor_hourly_logs = [];
      } finally {
        this.loading = false;
      }
    },
    getAquaLabUsersDevices() {
      const devicesToPond = {
        "5f7cf914bf214921efceda4a": [
          {
            device_id: "70B3D54995950A17",
            device_code: "70B3D54995950A17",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("25-11-2021"),
            to_date: this.getDateFormated("15-09-2022")
          },
          {
            device_id: "70B3D549902DDBC7",
            device_code: "70B3D549902DDBC7",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("25-11-2021"),
            to_date: this.getDateFormated("08-06-2022")
          },
          {
            device_id: "70B3D5499226B8C8",
            device_code: "70B3D5499226B8C8",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("16-09-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5f7cf6bdbf214921efcb520c": [
          {
            device_id: "70B3D5499EDA7E1E",
            device_code: "70B3D5499EDA7E1E",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("08-06-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5f7cf6bdbf214921efcb520d": [
          {
            device_id: "70B3D5499319898B",
            device_code: "70B3D5499319898B",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("25-11-2021"),
            to_date: this.getTodaysDate()
          }
        ],
        "5f7cf6bdbf214921efcb520b": [
          {
            device_id: "70B3D54999533BE3",
            device_code: "70B3D54999533BE3",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("25-11-2021"),
            to_date: this.getDateFormated("08-06-2022")
          },
          {
            device_id: "70B3D5499C87B5D2",
            device_code: "70B3D5499C87B5D2",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("16-02-2022"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D5499FDC97FF",
            device_code: "70B3D5499FDC97FF",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("25-11-2021"),
            to_date: this.getDateFormated("16-02-2022")
          },
          {
            device_id: "70B3D5499935FD25",
            device_code: "70B3D5499935FD25",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("08-06-2022"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D5499825AD39",
            device_code: "70B3D5499825AD39",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            cultures: [
              {
                start_date: "2021-11-18T00:00:00.000Z",
                end_date: "2021-11-18T00:00:00.000Z"
              }
            ],
            from_date: this.getDateFormated("06-11-2020"),
            to_date: this.getDateFormated("23-11-2021")
          }
        ],
        "5f7cf6bdbf214921efcb520a": [
          {
            device_id: "70B3D54994B98CFC",
            device_code: "70B3D54994B98CFC",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("19-10-2020"),
            to_date: this.getDateFormated("08-06-2022")
          },
          {
            device_id: "70B3D5499A28E4FB",
            device_code: "70B3D5499A28E4FB",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("25-11-2021"),
            to_date: this.getDateFormated("08-06-2022")
          },
          {
            device_id: "70B3D54999533BE3",
            device_code: "70B3D54999533BE3",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("08-06-2022"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D5499904AE67",
            device_code: "70B3D5499904AE67",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("08-06-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968650": [
          {
            device_id: "70B3D549946AE0C2",
            device_code: "70B3D549946AE0C2",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968656": [
          {
            device_id: "70B3D5499AF49396",
            device_code: "70B3D5499AF49396",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b347839": [
          {
            device_id: "70B3D5499DD1D420",
            device_code: "70B3D5499DD1D420",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b3477e1": [
          {
            device_id: "70B3D54995ED9698",
            device_code: "70B3D54995ED9698",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968692": [
          {
            device_id: "70B3D54990BDB2D3",
            device_code: "70B3D54990BDB2D3",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d296868b": [
          {
            device_id: "70B3D54990DD1D42",
            device_code: "70B3D54990DD1D42",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968685": [
          {
            device_id: "70B3D5499F2B9F12",
            device_code: "70B3D5499F2B9F12",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b3477e2": [
          {
            device_id: "70B3D549903BC95D",
            device_code: "70B3D549903BC95D",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b3477e3": [
          {
            device_id: "70B3D549932F621F",
            device_code: "70B3D549932F621F",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968648": [
          {
            device_id: "70B3D54990BD8394",
            device_code: "70B3D54990BD8394",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968671": [
          {
            device_id: "70B3D549939E5D1A",
            device_code: "70B3D549939E5D1A",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d296867b": [
          {
            device_id: "70B3D5499031C1BD",
            device_code: "70B3D5499031C1BD",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968680": [
          {
            device_id: "70B3D54999827431",
            device_code: "70B3D54999827431",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b347837": [
          {
            device_id: "70B3D549965C29A8",
            device_code: "70B3D549965C29A8",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968698": [
          {
            device_id: "70B3D5499D05B8FB",
            device_code: "70B3D5499D05B8FB",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968677": [
          {
            device_id: "70B3D54993172268",
            device_code: "70B3D54993172268",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d296869a": [
          {
            device_id: "70B3D5499CB1874E",
            device_code: "70B3D5499CB1874E",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("14-04-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "624214d8336d0e04cdf8a2d5": [
          {
            device_id: "70B3D5499D7C4490",
            device_code: "70B3D5499D7C4490",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "6242154e336d0e04cdf8a34c": [
          {
            device_id: "70B3D54993B24169",
            device_code: "70B3D54993B24169",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "6246e905a6e11b04b0f250ce": [
          {
            device_id: "70B3D5499FCA6390",
            device_code: "70B3D5499FCA6390",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "6242142b336d0e04cdf8a206": [
          {
            device_id: "70B3D54991E9C8E3",
            device_code: "70B3D54991E9C8E3",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "62421478336d0e04cdf8a23e": [
          {
            device_id: "70B3D5499B4DF145",
            device_code: "70B3D5499B4DF145",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "60afce0b241e97c16b6f7bb3": [
          {
            device_id: "70B3D5499A819D4F",
            device_code: "70B3D5499A819D4F",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "60afbf7b241e97c16b3d5cb1": [
          {
            device_id: "70B3D5499044D719",
            device_code: "70B3D5499044D719",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "624f3af7f4c6f60cd76b5206": [
          {
            device_id: "70B3D5499F46DD6D",
            device_code: "70B3D5499F46DD6D",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "624f3abaf4c6f60cd76b51f0": [
          {
            device_id: "70B3D5499AD5410A",
            device_code: "70B3D5499AD5410A",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "6255deccf4c6f60cd7719aaa": [
          {
            device_id: "70B3D54991EBC123",
            device_code: "70B3D54991EBC123",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "624e357c6e6a6a0cf4a5d657": [
          {
            device_id: "70B3D54993D78246",
            device_code: "70B3D54993D78246",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "624e34f76e6a6a0cf4a5d62e": [
          {
            device_id: "70B3D5499F08EDAD",
            device_code: "70B3D5499F08EDAD",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "624e3528f4c6f60cd76a3396": [
          {
            device_id: "70B3D5499EF5CF3F",
            device_code: "70B3D5499EF5CF3F",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("30-05-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "600f10591f52842fd3f61940": [
          {
            device_id: "70B3D5499BEB9514",
            device_code: "70B3D5499BEB9514",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("13-06-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "602c00f1d673a8b7247bf345": [
          {
            device_id: "70B3D549981B632E",
            device_code: "70B3D549981B632E",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("13-06-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "605517a9e6964cd971f33e43": [
          {
            device_id: "70B3D54997CD5525",
            device_code: "70B3D54997CD5525",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("13-06-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b3478dc": [
          {
            device_id: "70B3D54995560F14",
            device_code: "70B3D54995560F14",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-11-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b3478db": [
          {
            device_id: "70B3D5499C272D46",
            device_code: "70B3D5499C272D46",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-11-2022"),
            to_date: this.getTodaysDate()
          }
        ],
        "633ef786a6d907137b454a2f": [ // 210
          {
            device_id: "70B3D5499432573D",
            device_code: "70B3D5499432573D",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("31-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635173dde4dff36aab3fad0c": [ // 209
          {
            device_id: "70B3D5499432573D",
            device_code: "70B3D5499432573D",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("18-02-2023"),
            to_date: this.getDateFormated("30-03-2023")
          }
        ],
        "626abf63d2686063259a2d6a": [
          {
            device_id: "70B3D54996D9B3D7",
            device_code: "70B3D54996D9B3D7",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-11-2022"),
            to_date: this.getDateFormated("02-03-2023")
          }
        ],
        "639510842f1c06231a144700": [ // 242
          {
            device_id: "70B3D54996D9B3D7",
            device_code: "70B3D54996D9B3D7",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-03-2023"),
            to_date: this.getDateFormated("06-07-2023")
          }
        ],
        "626961936e6a6a0cf4c138ad": [ // 243
          {
            device_id: "70B3D54996D9B3D7",
            device_code: "70B3D54996D9B3D7",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("07-07-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "629a38525defcf65413167a4": [ // 116
          {
            device_id: "70B3D5499F66AC56",
            device_code: "70B3D5499F66AC56",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("04-05-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "629a37f590cd18652463bb8b": [ // 119
          {
            device_id: "70B3D54991335AF7",
            device_code: "70B3D54991335AF7",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("04-05-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "629a38375defcf6541316780": [ // 117
          {
            device_id: "70B3D54991335AF7",
            device_code: "70B3D54991335AF7",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-04-2023"),
            to_date: this.getDateFormated("03-05-2023")
          }
        ],
        "629a386c5defcf65413167d5": [ // 115
          {
            device_id: "70B3D5499F66AC56",
            device_code: "70B3D5499F66AC56",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("31-03-2023"),
            to_date: this.getDateFormated("03-05-2023")
          }
        ],
        "63e0fd6846001c05aaacc8f8": [ // ps-15
          {
            device_id: "70B3D54999445E83",
            device_code: "70B3D54999445E83",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D5499C02DE07",
            device_code: "70B3D5499C02DE07",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D54993D391C6",
            device_code: "70B3D54993D391C6",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63cc206532fdc918764bc580": [ // ps-16
          {
            device_id: "70B3D549917B0728",
            device_code: "70B3D549917B0728",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D549918E0DE8",
            device_code: "70B3D549918E0DE8",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D54990D7C449",
            device_code: "70B3D54990D7C449",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63e0fea346001c05aaacd0ef": [ // ps-20
          {
            device_id: "70B3D549907792BA",
            device_code: "70B3D549907792BA",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D54994CD6BDC",
            device_code: "70B3D54994CD6BDC",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D5499B6E29CD",
            device_code: "70B3D5499B6E29CD",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "62ddb930c3afde6ad92cbd15": [ // ps-58
          {
            device_id: "70B3D5499D4422DF",
            device_code: "70B3D5499D4422DF",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D54993931A62",
            device_code: "70B3D54993931A62",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          },
          {
            device_id: "70B3D549984391BB",
            device_code: "70B3D549984391BB",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5eec4d75f2cd47c5529fa6be": [ // ps-34
          {
            device_id: "70B3D549975B97F1",
            device_code: "70B3D549975B97F1",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5eec4d75f2cd47c5529fa6c1": [ // ps-33
          {
            device_id: "70B3D549989AE320",
            device_code: "70B3D549989AE320",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e7fb7c93408bd91128d1f13": [ // ps-07
          {
            device_id: "70B3D5499214838D",
            device_code: "70B3D5499214838D",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b34752b": [ // ps-08
          {
            device_id: "70B3D54997A6022E",
            device_code: "70B3D54997A6022E",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e8b24cacb966399022a2c0a": [ // ps-06
          {
            device_id: "70B3D549983C0D1C",
            device_code: "70B3D549983C0D1C",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e8f1949cb966399026ebd58": [ // ps-04
          {
            device_id: "70B3D5499CB7DE27",
            device_code: "70B3D5499CB7DE27",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5ed667d8f2cd47c552e0b68a": [ // ps-05
          {
            device_id: "70B3D5499CCB4733",
            device_code: "70B3D5499CCB4733",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e58aaf12c599d13d2968ae5": [ // ps-31
          {
            device_id: "70B3D5499F79DF95",
            device_code: "70B3D5499F79DF95",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5ed667d8f2cd47c552e0b72d": [ // ps-30
          {
            device_id: "70B3D54997A7238C",
            device_code: "70B3D54997A7238C",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e5c85792c599d13d245b6d7": [ // ps-28
          {
            device_id: "70B3D54993B40D55",
            device_code: "70B3D54993B40D55",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5ed7ffa2f2cd47c552648e05": [ // ps-29
          {
            device_id: "70B3D549978D1BB6",
            device_code: "70B3D549978D1BB6",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5ed667d7f2cd47c552e0b52a": [ // ps-26
          {
            device_id: "70B3D54994110C3A",
            device_code: "70B3D54994110C3A",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5ed667d7f2cd47c552e0b4ec": [ // ps-27
          {
            device_id: "70B3D54997E341DA",
            device_code: "70B3D54997E341DA",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5ed667d8f2cd47c552e0b69a": [ // ps-35
          {
            device_id: "70B3D5499EF7D442",
            device_code: "70B3D5499EF7D442",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b34752a": [ // ps-09
          {
            device_id: "70B3D549984F8D47",
            device_code: "70B3D549984F8D47",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5efdd9e0be50fddb61943492": [ // ps-38
          {
            device_id: "70B3D5499EB337EE",
            device_code: "70B3D5499EB337EE",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e9ec24d657153da6326d966": [ // ps-37
          {
            device_id: "70B3D5499E1824FD",
            device_code: "70B3D5499E1824FD",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5ed667d8f2cd47c552e0b6a4": [ // ps-25
          {
            device_id: "70B3D5499B63988E",
            device_code: "70B3D5499B63988E",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63e101f54e487605a3b9e7fe": [ // pc-07
          {
            device_id: "70B3D5499FF7E647",
            device_code: "70B3D5499FF7E647",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63e1014946001c05aaacd927": [ // pc-18
          {
            device_id: "70B3D54997D84F6A",
            device_code: "70B3D54997D84F6A",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b347513": [ // ps-40
          {
            device_id: "70B3D5499EB80BE1",
            device_code: "70B3D5499EB80BE1",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5e1897b5188b23258b347529": [ // ps-41
          {
            device_id: "70B3D5499DF0F994",
            device_code: "70B3D5499DF0F994",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "60e257eecbe3d61e50510207": [ // pc-38
          {
            device_id: "70B3D5499250EF13",
            device_code: "70B3D5499250EF13",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("09-02-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ad896136ed45b2e0e5b9a": [ // ps-09
          {
            device_id: "70B3D549987CAE11",
            device_code: "70B3D549987CAE11",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ad781a0e0fc5b4bc722cd": [ // ps-71
          {
            device_id: "70B3D54997E531C8",
            device_code: "70B3D54997E531C8",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ae42b136ed45b2e0e70cd": [ // ps-11
          {
            device_id: "70B3D54991AF8892",
            device_code: "70B3D54991AF8892",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "639b85802f1c06231a1ea24e": [ // ps-06
          {
            device_id: "70B3D54990FCA639",
            device_code: "70B3D54990FCA639",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635af26b136ed45b2e0e8681": [ // ps-15
          {
            device_id: "70B3D5499C896DC2",
            device_code: "70B3D5499C896DC2",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ad54b136ed45b2e0e56b1": [ // ps-07
          {
            device_id: "70B3D5499ACBEC13",
            device_code: "70B3D5499ACBEC13",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ad7f1136ed45b2e0e5a72": [ // ps-72
          {
            device_id: "70B3D5499E811C1C",
            device_code: "70B3D5499E811C1C",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ae356a0e0fc5b4bc735d7": [ // ps-10
          {
            device_id: "70B3D549977BEA21",
            device_code: "70B3D549977BEA21",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ae650a0e0fc5b4bc739ce": [ // ps-14
          {
            device_id: "70B3D5499CBA06B0",
            device_code: "70B3D5499CBA06B0",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ad672136ed45b2e0e5820": [ // ps-08
          {
            device_id: "70B3D5499089AE32",
            device_code: "70B3D5499089AE32",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ae4eaa0e0fc5b4bc7381a": [ // ps-12
          {
            device_id: "70B3D5499EB7E8E4",
            device_code: "70B3D5499EB7E8E4",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5f189d200c6e412ab6bdef81": [ // ps-25
          {
            device_id: "70B3D5499A7F89FE",
            device_code: "70B3D5499A7F89FE",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5f15e9c25f9de5fbc1a7ca2e": [ // ps-40
          {
            device_id: "70B3D54993ADFA39",
            device_code: "70B3D54993ADFA39",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "635ae577136ed45b2e0e720f": [ // ps-13
          {
            device_id: "70B3D549900D1D82",
            device_code: "70B3D549900D1D82",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "5f1479c0be50fddb61152a57": [ // ps-24
          {
            device_id: "70B3D5499D0388CD",
            device_code: "70B3D5499D0388CD",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63979acb2f1c06231a17f64b": [ // ps-33B
          {
            device_id: "70B3D549909CE957",
            device_code: "70B3D549909CE957",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "639799852f1c06231a17f4d9": [ // ps-32
          {
            device_id: "70B3D549964EF58B",
            device_code: "70B3D549964EF58B",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "639790422f1c06231a17e812": [ // ps-25
          {
            device_id: "70B3D54996B2488E",
            device_code: "70B3D54996B2488E",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "6397938bd4ff0922fda37b03": [ // ps-26
          {
            device_id: "70B3D549980A4297",
            device_code: "70B3D549980A4297",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63978f46d4ff0922fda37522": [ // ps-22
          {
            device_id: "70B3D54998BB5CFB",
            device_code: "70B3D54998BB5CFB",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63979815d4ff0922fda382bc": [ // ps-29
          {
            device_id: "70B3D54999B325F3",
            device_code: "70B3D54999B325F3",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63979892d4ff0922fda3837b": [ // ps-30
          {
            device_id: "70B3D5499ABB148F",
            device_code: "70B3D5499ABB148F",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63e2f30d46001c05aab02572": [ // ps-31
          {
            device_id: "70B3D5499AC1DE4F",
            device_code: "70B3D5499AC1DE4F",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63978ff1d4ff0922fda376c0": [ // ps-24
          {
            device_id: "70B3D5499DD5F5A3",
            device_code: "70B3D5499DD5F5A3",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "639797a8d4ff0922fda38247": [ // ps-28
          {
            device_id: "70B3D5499E1B5B6B",
            device_code: "70B3D5499E1B5B6B",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getDateFormated("24-03-2025")
          }
        ],
        "63979440d4ff0922fda37c07": [ // ps-27
          {
            device_id: "70B3D5499F4E4718",
            device_code: "70B3D5499F4E4718",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "63979a772f1c06231a17f5e0": [ // ps-33A
          {
            device_id: "70B3D5499F51DC9D",
            device_code: "70B3D5499F51DC9D",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("03-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
        "643b1698bea6544e0ea182d9": [ // 23
          {
            device_id: "70B3D549943B0D61",
            device_code: "70B3D549943B0D61",
            manufacture_by: "AQUALAB",
            settings: { ph_sensor_control: false },
            from_date: this.getDateFormated("06-03-2023"),
            to_date: this.getTodaysDate()
          }
        ],
      };
      if (
        this.getUserId === "5f6a182c0fa44b545871ecb7" ||
        this.getUserId === "5e1897b5188b23258b34645d" ||
        this.getUserId === "5e72f9493408bd9112319685" ||
        this.getUserId === "5e1897b5188b23258b346616" ||
        this.getUserId === "5e1897b5188b23258b346465" ||
        this.getUserId === "5e1897b5188b23258b3464ae" ||
        this.getUserId === "6392bdb9d4ff0922fd9c1aaa"
      ) {
        console.log("this.params", this.params);
        const finalDeviceList = this.checkDeviceBasedOnDateRange(
          devicesToPond[this.pondId]
        );
        return finalDeviceList;
      }
      if (
        this.getCreatedBy === "5f6a182c0fa44b545871ecb7" ||
        this.getCreatedBy === "5e1897b5188b23258b34645d" ||
        this.getCreatedBy === "5e72f9493408bd9112319685" ||
        this.getCreatedBy === "5e1897b5188b23258b346616" ||
        this.getCreatedBy === "5e1897b5188b23258b346465" ||
        this.getCreatedBy === "5e1897b5188b23258b3464ae" ||
        this.getCreatedBy === "6392bdb9d4ff0922fd9c1aaa"
      ) {
        return devicesToPond[this.pondId];
      }
      return undefined;
    },
    getTodaysDate() {
      return this.dhm__dateUtilsLib.startOfDay(this.dhm__getTodayInUserTZ);
    },
    getDateFormated(data) {
      const formattedData = data
        .split("-")
        .reverse()
        .join("-");
      return this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCISO(new Date(formattedData)),
          this.getUserTimeZoneString
        )
      );
    },
    getToDateFormated(data) {
      const formattedData = data
        .split("-")
        .reverse()
        .join("-");
      return this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCISO(new Date(formattedData)),
          this.getUserTimeZoneString
        )
      );
    },
    checkDeviceBasedOnDateRange(devices) {
      const finalDeviceList = [];
      //  const today = this.dhm__dateUtilsLib.startOfDay(
      //     this.dhm__getTodayInUserTZ
      //   );
      const userSelectedDateStart = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCISO(new Date(this.params?.from_date)),
          this.getUserTimeZoneString
        )
      );
      const userSelectedDateEnd = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCISO(new Date(this.params?.to_date)),
          this.getUserTimeZoneString
        )
      );
      if (devices && devices.length > 0) {
        devices.filter((device) => {
          if (
            userSelectedDateEnd >= device?.from_date &&
            userSelectedDateStart <= device?.to_date
          ) {
            finalDeviceList.push(device);
          }
          // device.from_date = this.dhm__dateUtilsLib.startOfDay(
          //   this.dhm__dateUtilsLib.utcToZonedTime(
          //     this.dhm__castUserUTCToUTCISO(new Date("2021-10-25")),
          //     this.getUserTimeZoneString
          //   )
          // );
          // device.to_date = this.dhm__dateUtilsLib.startOfDay(
          //   this.dhm__dateUtilsLib.utcToZonedTime(
          //     this.dhm__castUserUTCToUTCISO(new Date("2022-05-25")),
          //     this.getUserTimeZoneString
          //   )
          // );
          //   console.log(
          //     this.dhm__dateUtilsLib.formatDate(
          //       this.dhm__dateUtilsLib.parse(
          //         this.params?.from_date,
          //         this.dhm__dateUtilsLib.isoFormatString,
          //         new Date()
          //       ),
          //       "dd-MM-yyy"
          //     )
          //   );
          //   const isBefore = this.dhm__dateUtilsLib.isBefore(
          //     userSelectedDateStart,
          //     device?.from_date
          //   );
          //   const isStartDateEqual = this.dhm__dateUtilsLib.isEqual(
          //     userSelectedDateStart,
          //     device?.from_date
          //   );
          //   const isAfter = this.dhm__dateUtilsLib.isAfter(
          //     userSelectedDateEnd,
          //     device?.to_date
          //   );
          //   const isEndDateEqual = this.dhm__dateUtilsLib.isEqual(
          //     userSelectedDateEnd,
          //     device?.to_date
          //   );

          //   if (!isBefore && !isAfter) {
          //     finalDeviceList.push(device);
          //   } else if (isStartDateEqual && !isAfter) {
          //     finalDeviceList.push(device);
          //   } else if (isEndDateEqual && !isBefore) {
          //     finalDeviceList.push(device);
          //   } else if (isStartDateEqual && isEndDateEqual) {
          //     finalDeviceList.push(device);
          //   }
        });
      }
      return finalDeviceList;
    },
    async manageApiCallByUserId() {
      let pond_guards = this.getArrSelectedPondPGs || [];
      pond_guards = pond_guards.map((x) => {
        x.manufacture_by = "ERUVAKA";
        return x;
      });
      const aquaLabPgs = this.getAquaLabUsersDevices();
      if (aquaLabPgs) {
        pond_guards = [...pond_guards, ...aquaLabPgs];
      }
      if (pond_guards.length > 0) {
        this.listPondGuards = pond_guards;
        if (!this.selectPG) {
          this.selectPG = this.listPondGuards[0];
        } else {
          const isExistingInNewList = this.setPondGuards.has(this.selectPG._id);
          if (!isExistingInNewList) {
            this.selectPG = this.listPondGuards[0];
          }
        }
        await this.handleFetchPGData(this.selectPG);
        this.chm__initAxisTextKeys("Comn_date_and_time", [
          "Comn_do_ph",
          "",
          "Comn_temperature_c_salinity",
          "Comn_oxygen_saturation"
        ]);
        this.chm__initChartLang();
      } else {
        this.listPondGuards = [];
        this.selectPG = undefined;
        this.sensor_hourly_logs = [];
      }
    },
    handleFetchPGData: async function(pg) {
      try {
        let response;
        const selected_from_date = this.dhm__dateUtilsLib.startOfDay(
                                    this.dhm__dateUtilsLib.utcToZonedTime(
                                      this.dhm__castUserUTCToUTCISO(new Date(this.params?.from_date)),
                                      this.getUserTimeZoneString
                                    )
                                  );
        const selected_to_date = this.dhm__dateUtilsLib.startOfDay(
                                    this.dhm__dateUtilsLib.utcToZonedTime(
                                      this.dhm__castUserUTCToUTCISO(new Date(this.params?.to_date)),
                                      this.getUserTimeZoneString
                                    )
                                  );
        if (pg.manufacture_by === "AQUALAB") {
          const aqualab_from_date = new Date(this.getAquaLabUsersDevices()[0].from_date);
          const aqualab_to_date = new Date(this.getAquaLabUsersDevices()[0].to_date);
          if (aqualab_from_date > selected_from_date) {
            this.params.from_date = moment(aqualab_from_date).format("YYYY-MM-DD") + "T00:00:00.000Z";
          }
          if (aqualab_to_date <= selected_to_date) {
            this.params.to_date = moment(aqualab_to_date).format("YYYY-MM-DD") + "T23:59:59.999Z";
          }
          response = await this.fetchAquaLabPondGuardData({
            pgId: pg.device_id,
            params: this.params
          });
        } else {
          this.sensor_Permissions = {};
          response = await this.fetchPondGuardData({
            pondId: this.pondId,
            pgId: pg.device_id,
            params: this.params
          });
        this.sensor_Permissions = response.sensor_status;
        }
        this.sensor_hourly_logs = response.pond_guard_data;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    pondGuardHourly(pondGuardDataDaysWise) {
      const dateHourlyO2 = {};
      const dateHourlySalinity = {};
      const dateHourlyDoSaturation = {};
      const dateHourlyPH = {};
      const dateHourlyTemp = {};
      const milliSecs = (date) => date.getTime();
      const getDate = (dateStr) => this.dhm__dateUtilsLib.parseISO(dateStr);
      const roundOff = (value) =>
        value ? +value.toFixed(2).toString() : value;
      pondGuardDataDaysWise.forEach((dayData) => {
        const timeSlotData = dayData.data;
        // the date from the server side is UTC with time equal to the user timezone
        const date = getDate(dayData.date);
        const dateTimeFeed = milliSecs(date);
        [dateHourlyO2, dateHourlyPH, dateHourlyTemp, dateHourlyDoSaturation, dateHourlySalinity].forEach((x) => {
          if (!x[dateTimeFeed]) {
            x[dateTimeFeed] = 0;
          }
        });
        dateHourlyDoSaturation[dateTimeFeed] = +roundOff(timeSlotData.do_saturation);
        dateHourlySalinity[dateTimeFeed] = +roundOff(timeSlotData.salinity);
        dateHourlyO2[dateTimeFeed] = +roundOff(timeSlotData.dissolved_oxygen);
        dateHourlyPH[dateTimeFeed] = +roundOff(timeSlotData.ph);
        dateHourlyTemp[dateTimeFeed] = +roundOff(timeSlotData.temperature);
      });
      let hourlyO2 = [];
      const timeFormat = (key) => {
        const format =
          this.dataViewOption === "RAW_DATA"
            ? CHART_DATE_FORMATS.dmyt
            : CHART_DATE_FORMATS.t;
        return format;
      };
      Object.keys(dateHourlyO2).forEach((key) => {
        const o2 = Number(dateHourlyO2[key]);
        if (o2 > 0) {
          hourlyO2.push({
            x: Number(key),
            y: o2,
            timeFormat: timeFormat(key)
          });
        }
      });
      let hourlyPH = [];
      Object.keys(dateHourlyPH).forEach((key) => {
        hourlyPH.push({
          x: Number(key),
          y: Number(dateHourlyPH[key]),
          timeFormat: timeFormat(key)
        });
      });
      let hourlyTemp = [];
      Object.keys(dateHourlyTemp).forEach((key) => {
        const temp = Number(dateHourlyTemp[key]);
        if (temp > 0) {
          hourlyTemp.push({
            x: Number(key),
            y: temp,
            timeFormat: timeFormat(key)
          });
        }
      });
      let hourlySalinity = [];
      Object.keys(dateHourlySalinity).forEach((key) => {
        const temp = Number(dateHourlySalinity[key]);
        if (temp > 0) {
          hourlySalinity.push({
            x: Number(key),
            y: temp,
            timeFormat: timeFormat(key)
          });
        }
      });
      let hourlyDoSaturation = [];
      Object.keys(dateHourlyDoSaturation).forEach((key) => {
        const temp = Number(dateHourlyDoSaturation[key]);
        if (temp > 0) {
          hourlyDoSaturation.push({
            x: Number(key),
            y: temp,
            timeFormat: timeFormat(key)
          });
        }
      });
      hourlyO2 = hourlyO2.sort((a, b) => a.x - b.x);
      hourlyPH = hourlyPH.sort((a, b) => a.x - b.x);
      hourlyTemp = hourlyTemp.sort((a, b) => a.x - b.x);
      hourlySalinity = hourlySalinity.sort((a, b) => a.x - b.x);
      hourlyDoSaturation = hourlyDoSaturation.sort((a, b) => a.x - b.x);
      return { hourlyO2, hourlyPH, hourlyTemp, hourlySalinity, hourlyDoSaturation };
    },
    pondGuardHourlyAvgData: function(pondGuardDataDaysWise, tempDateRange) {
      const groupByHourlyTemp = {};
      const groupByHourlyO2 = {};
      const groupByHourlyPH = {};
      const groupByHourlySalinity = {};
      const groupByHourlyDoSaturation = {};
      const dateRangeDiff = {};
      const roundOff = (value) => +value.toFixed(2).toString();
      const milliSecs = (date) => date.getTime();
      const getDate = (dateStr) => this.dhm__dateUtilsLib.parseISO(dateStr);
      const difference = this.dhm__dateUtilsLib.differenceInHours(
        tempDateRange[1],
        getDate(tempDateRange[0])
      );
      const timeFormat = (key) => {
        const format =
          this.dataViewOption === "RAW_DATA"
            ? CHART_DATE_FORMATS.dmyt
            : CHART_DATE_FORMATS.t;
        return format;
      };
      pondGuardDataDaysWise.forEach((dayData) => {
        const timeSlotData = dayData.data;
        const hours = Number(timeSlotData.time.substr(0, 2));
        let dateTimeFeed;
        if (difference < 23) {
          dateTimeFeed = this.dhm__dateUtilsLib.startOfHour(timeSlotData.date);
        } else {
          dateTimeFeed = this.dhm__dateUtilsLib.add(
            this.dhm__dateUtilsLib.startOfDay(tempDateRange[0]),
            {
              hours
            }
          );
        }
        dateTimeFeed = milliSecs(
          this.dhm__dateUtilsLib.castBrowserDateToUserUTC(dateTimeFeed)
        );
        [
          groupByHourlyTemp,
          groupByHourlyO2,
          groupByHourlyPH,
          groupByHourlySalinity,
          groupByHourlyDoSaturation,
          dateRangeDiff
        ].forEach((x) => {
          if (!x[dateTimeFeed]) {
            x[dateTimeFeed] = 0;
          }
        });
        groupByHourlyTemp[dateTimeFeed] += timeSlotData.temperature;
        groupByHourlyO2[dateTimeFeed] += timeSlotData.dissolved_oxygen;
        groupByHourlyPH[dateTimeFeed] += timeSlotData.ph;
        groupByHourlySalinity[dateTimeFeed] += timeSlotData.salinity;
        groupByHourlyDoSaturation[dateTimeFeed] += timeSlotData.do_saturation;
        dateRangeDiff[dateTimeFeed]++;
      });
      Object.keys(groupByHourlyTemp).forEach((key) => {
        groupByHourlyTemp[key] = +roundOff(
          groupByHourlyTemp[key] / dateRangeDiff[key]
        );
        groupByHourlyO2[key] = +roundOff(
          groupByHourlyO2[key] / dateRangeDiff[key]
        );
        groupByHourlyPH[key] = +roundOff(
          groupByHourlyPH[key] / dateRangeDiff[key]
        );
        groupByHourlySalinity[key] = +roundOff(
          groupByHourlySalinity[key] / dateRangeDiff[key]
        );
        groupByHourlyDoSaturation[key] = +roundOff(
          groupByHourlyDoSaturation[key] / dateRangeDiff[key]
        );
      });
      const padDateRanges = Object.keys(groupByHourlyTemp);
      let [
        resultGroupByHourlyTemp,
        resultGroupByHourlyO2,
        resultGroupByHourlyPH,
        resultGroupByHourlySalinity,
        resultGroupByHourlyDoSaturation
      ] = padDateRanges.reduce(
        (acc, dateKey) => {
          [
            groupByHourlyTemp[dateKey],
            groupByHourlyO2[dateKey],
            groupByHourlyPH[dateKey],
            groupByHourlySalinity[dateKey],
            groupByHourlyDoSaturation[dateKey]
          ].forEach((value, index) => {
            if (value) {
              acc[index].push({
                x: Number(dateKey),
                y: value,
                timeFormat: timeFormat(dateKey)
              });
            } else {
              acc[index].push({
                x: Number(dateKey),
                y: null,
                timeFormat: timeFormat(dateKey)
              });
            }
          });
          return acc;
        },
        [[], [], []]
      );
      resultGroupByHourlyTemp = resultGroupByHourlyTemp.sort(
        (a, b) => a.x - b.x
      );
      resultGroupByHourlyO2 = resultGroupByHourlyO2.sort((a, b) => a.x - b.x);
      resultGroupByHourlyPH = resultGroupByHourlyPH.sort((a, b) => a.x - b.x);
      resultGroupByHourlySalinity = resultGroupByHourlySalinity.sort((a, b) => a.x - b.x);
      resultGroupByHourlyDoSaturation = resultGroupByHourlyDoSaturation.sort((a, b) => a.x - b.x);
      return {
        groupByHourlyTemp: resultGroupByHourlyTemp,
        groupByHourlyO2: resultGroupByHourlyO2,
        groupByHourlyPH: resultGroupByHourlyPH,
        groupByHourlySalinity: resultGroupByHourlySalinity,
        groupByHourlyDoSaturation: resultGroupByHourlyDoSaturation
      };
    },
    handleChangeInPg: async function(pm) {
      this.loading = true;
      await this.handleFetchPGData(pm);
      this.handleCommand(this.dataViewOption);
      this.loading = false;
      this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - PondGuard Graph - PondGuard - Dropdown" });
    },
    handleValueChange: function(currentSelectedValues) {
      // converting YYYY-MM-DD HH:mm strings to utc String
      let tempValues = currentSelectedValues;
      tempValues = currentSelectedValues.map((x) => {
        return this.dhm__dateUtilsLib.castBrowserDateToUserUTC(
          this.dhm__dateUtilsLib.parse(x, this.dateFormat, new Date()),
          this.dhm__getUserTZStringObj
        );
      });
      // init from_date , to_date to the user time zone
      this.params.from_date = tempValues[0].toISOString();
      this.params.to_date = tempValues[1].toISOString();
      this.initComponent();
    },
    handleDateValueChange(currentSelectedValues) {
      this.handleValueChange(currentSelectedValues);
      this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - PondGuard Graph - Date Filter" });
    },
    chm__initChart() {
      let result = {
        hourlyO2: [],
        hourlyPH: [],
        hourlyTemp: []
      };
      let hourlyO2 = [];
      let hourlyPH = [];
      let hourlyTemp = [];
      let hourlySalinity = [];
      let hourlyDoSaturation = [];
      // where utc is the time in user time zone
      const tempDateRange = [
        this.dhm__dateUtilsLib.parseISO(this.params.from_date),
        this.dhm__dateUtilsLib.parseISO(this.params.from_date)
      ];
      if (this.sensor_hourly_logs.length > 0) {
        if (this.dataViewOption === "RAW_DATA") {
          result = this.pondGuardHourly(this.sensor_hourly_logs);
          hourlyO2 = result.hourlyO2;
          hourlyPH = result.hourlyPH;
          hourlyTemp = result.hourlyTemp;
          hourlySalinity = result.hourlySalinity;
          hourlyDoSaturation = result.hourlyDoSaturation;
        } else if (this.dataViewOption === "AVG_DATA") {
          result = this.pondGuardHourlyAvgData(
            this.sensor_hourly_logs,
            tempDateRange
          );
          hourlyO2 = result.groupByHourlyO2;
          hourlyPH = result.groupByHourlyPH;
          hourlyTemp = result.groupByHourlyTemp;
          hourlySalinity = result.groupByHourlySalinity;
          hourlyDoSaturation = result.groupByHourlyDoSaturation;
        }
      }
      this.initChartSeries(hourlyO2, hourlyPH, hourlyTemp, hourlySalinity, hourlyDoSaturation);
    },
    initChartSeries(hourlyO2, hourlyPH, hourlyTemp, hourlySalinity, hourlyDoSaturation) {
      this.chartOptions.series = [];
      this.chartOptions.tooltip.xDateFormat = `${
        this.dateFormatObject[this.upm__getFormatDateString]
      } ${this.timeFormatObject[this.upm__getFormatTimeString]}`;
      if (hourlyO2.length > 0 || hourlyPH.length > 0 || hourlyTemp.length > 0) {
        const nameObj = (series_name) => ({
          series_name,
          device_code: this.sensor_hourly_logs[0].pond_guard_code,
          device_code_text: this.$t("Comn_device_id")
        });
        // time format
        this.chartOptions.series.push({
          name: nameObj(this.$t("PM_dissolved_oxygen_mgl")),
          data: hourlyO2,
          color: "#66CD00",
          type: "spline",
          yAxis: 0,
          pointWidth: 10,
          visible: true,
          // TODO: Need to integrate pg critical do and low do values
          zones: [
            {
              className: "critical-do",
              value: this.criticalLowerLimit,
              color: "#FF0000"
            },
            {
              className: "low-do",
              value: this.lowerLimit,
              color: "#ffa500"
            }
          ]
        });
        if (this.selectPG.settings.ph_sensor_control) {
          this.chartOptions.series.push({
            name: nameObj(this.$t("Comn_ph")),
            data: hourlyPH,
            type: "spline",
            yAxis: 0,
            pointWidth: 10,
            color: "#FFA500",
            visible: false
          });
        }
        this.chartOptions.series.push({
          name: nameObj(this.$t("Comn_temperature_c")),
          data: hourlyTemp,
          type: "spline",
          yAxis: 2,
          pointWidth: 10,
          color: "#4572A7",
          visible: true
        });
        if (this.sensor_Permissions.salinity_sensor_enable) {
          this.chartOptions.series.push({
            name: nameObj(this.$t("Comn_salinity")),
            data: hourlySalinity,
            type: "spline",
            yAxis: 2,
            pointWidth: 10,
            color: "#47caf5",
            visible: false
          });
        }
        if (this.sensor_Permissions.do_saturation_sensor_enable) {
          this.chartOptions.series.push({
            name: nameObj(this.$t("Comn_oxygen_saturation")),
            data: hourlyDoSaturation,
            type: "spline",
            yAxis: 3,
            pointWidth: 10,
            color: "#de3bf7",
            visible: false
          });
        }
        this.$nextTick(() => {
          if (!this.$refs.highcharts) return;
          const chart = this.$refs.highcharts.chart;
          const xAxis = chart.xAxis[0];
          xAxis.setExtremes(xAxis.dataMin, xAxis.dataMax);
        });
      }
    },
    chm__setAxisText() {
      this.chartOptions.xAxis.title.text = this.$t(this.xAxisTextKey);
      this.chartOptions.yAxis[0].title.text = this.$t(
        this.chm__yAxisTextKey[0]
      );
      this.chartOptions.yAxis[1].title.text = this.$t(
        this.chm__yAxisTextKey[1]
      );
      this.chartOptions.yAxis[2].title.text = this.$t(
        this.chm__yAxisTextKey[2]
      );
      this.chartOptions.yAxis[3].title.text = this.$t(
        this.chm__yAxisTextKey[3]
      );
    },
    handleCommand(command) {
      this.dataViewOption = command;
      this.chm__initChart();
    },
    handleChangeCommand(command) {
      this.handleCommand(command);
      this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - PondGuard Graph - Raw - Average - Dropdown" });
    }
  }
};
</script>
<style lang="scss">
.pg-status-graph {
  .pg-graph-filter {
    .el-range-editor--mini {
      @include responsiveProperty(flex-basis, 65%, 50%, 45%);
    }
    .select-pond-guard {
      @include responsiveProperty(flex-basis, 25%, 20%, 15%);
    }
    .select-graph-data-type {
      @include responsiveProperty(flex-basis, 20%, 20%, 15%);
    }
  }
  .er-select .el-input__inner {
    @include responsiveProperty(height, 24px, 27px, 30px);
  }
  .el-input--suffix .el-input__inner {
    padding-left: 5px;
  }
  .download_button {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
}
.pgdownload_dropdown {
  .el-dropdown-menu__item {
    list-style: none;
    padding: 0 10px;
    line-height: 15px;
    margin: 0;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    color: #606266;
    cursor: pointer;
    outline: 0;
  }
}
</style>
